body {
    background-color: #ecd0cf;
}

.App {
    text-align: left;
    background-image: url(../public/assets/desktop.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    min-height: 100vh;
}

.App-header {
    background-color: #000000;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    vertical-align: top;
    color: white;
}

.contents {
    display: block;
    min-height: 50vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    padding-left: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: rgba(255, 255, 255, 0.4);
}

.content-layout {
    max-width: 800px;
}

.contents h2 {
    max-width: 60lvw;
    margin-bottom: 0.25em;
}

.contents h3 {
    max-width: 60lvw;
    margin-bottom: 1em;
}

footer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
}
